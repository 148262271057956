<template>
  <div class="fullScreen" :class="{ show: isDetailShow }" v-if="isDetailShow">
    <div v-loading="loading">
      <div class="fullScreenMain">
        <el-tabs v-model="activeName" class="fullScreenMainHeader">
          <el-tab-pane name="0" label="投标信息"> </el-tab-pane>
          <el-tab-pane name="1" label="中标结果"> </el-tab-pane>
          <el-tab-pane name="2" label="人员分配"> </el-tab-pane>
          <el-tab-pane name="3" label="成本总结" v-if="detailData.id"> </el-tab-pane>
          <el-tab-pane name="4" label="保证金管理" v-if="detailData.id"> </el-tab-pane>
        </el-tabs>
        <div class="fullScreenMainCon">
          <BidInfo
            v-show="activeName == 0"
            :formData="detailData"
            ref="BidInfoRef"
            :editType="editType"
            :isBidLog="isBidLog"
          ></BidInfo>
          <WinResult
            v-show="activeName == 1"
            ref="WinResultRef"
            :detailData="winResultData"
            :editType="editType"
          ></WinResult>
          <BidAssign
            ref="Assign"
            :options="fakeData"
            :editType="editType"
            v-show="activeName == 2"
          ></BidAssign>
          <CostTotal :options="fakeData.bidManagementCost" v-show="activeName == 3"></CostTotal>
          <BailManage
            ref="BailManage"
            :options="fakeData"
            :editType="editType"
            @onBidUpdateData="getData()"
            v-show="activeName == 4"
          ></BailManage>
        </div>
        <div class="fullScreenOperation">
          <!-- <el-button type="success" @click="tempAdd" v-if="canWrite">暂存</el-button> -->
          <el-button type="primary" @click="save" v-if="canWrite">保存</el-button>
          <el-button type="info" @click="back">返回</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  components: {
    BidInfo: () => import('@/views/publicView/bid/BidInfo.vue'),
    WinResult: () => import('@/views/publicView/bid/WinResult.vue'),
    CostTotal: () => import('@/views/publicView/bid/CostTotal.vue'),
    BidAssign: () => import('@/views/publicView/bid/Assign.vue'),
    BailManage: () => import('@/views/publicView/bid/BailManage.vue'),
  },

  props: {
    isDetailShow: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      activeName: '0',
      editType: '', //当前点击列表页的状态
      loading: false,
      fakeData: {},
      editType: '',
      isBidLog: false,
      detailData: {},
      winResultData: {},
    }
  },
  inject: ['re'],
  created() {},
  watch: {
    isDetailShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {},
    },
    data: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal) {
        this.editType = newVal.editType || ''
        this.isBidLog = newVal.isBidLog || false
        if (this.editType == 'bidEdit') {
          this.activeName = '4'
        }
        if (newVal.id) {
          this.getData()
        }
      },
    },
  },

  computed: {
    ...mapState({
      ZP_Members: state => state.bid.ZP_Members,
    }),
    canWrite() {
      switch (this.editType) {
        case 'add':
          return true
        case 'edit':
          return true
        case 'detail':
          return false
        case 'claim':
          return true
        default:
          return false
      }
    },
  },
  methods: {
    ...mapMutations('bid', ['SET_ZP_Members']),
    // async tempAdd() {
    //   if (this.activeName == 2) {
    //     if (this.$refs.Assign) {
    //       this.loading = true
    //       let bidMemberList = this.$refs.Assign.bidMemberList
    //       const deleteBidMemberList = this.$refs.Assign.deleteBidMemberList
    //       const arr = bidMemberList.flatMap(v => v.MemberList)
    //       arr.forEach(v => (v.bidManagementId = this.data.id))
    //       const obj = {
    //         bidMemberList: arr,
    //         deleteBidMemberList: deleteBidMemberList,
    //       }
    //       await this.$api.bid.addMember(obj)
    //       this.$emit('update:isDetailShow', false)
    //       this.loading = false
    //       this.$message.success('操作成功！')
    //       this.re()
    //     }
    //   } else {
    //     let req = this.$refs.BidInfoRef.temAdd()
    //     let file = this.$refs.WinResultRef.save()
    //     if (file.isWin == '') {
    //       req.bidManagementFileAddList = file.fileList
    //       this.loading = true
    //       const res = await this.$api.bid.addBid(req)
    //       this.$emit('update:isDetailShow', false)
    //       this.loading = false
    //       this.$message.success('操作成功！')
    //       this.re()
    //     } else {
    //       this.$message.warning('已选择中标结果无法暂存，请点击提交按钮上传标的信息')
    //     }
    //   }
    // },
    back() {
      this.SET_ZP_Members([])
      this.re()
    },
    async save() {
      let bidMembers = this.$refs.Assign.bidMembers
      const deleteBidMemberList = this.$refs.Assign.deleteBidMemberList
      const memberArr = bidMembers.flatMap(v => v.MemberList)
      memberArr.forEach(v => (v.bidManagementId = this.data.id))
      let req = this.$refs.BidInfoRef.save()
      if (req == 'warning') return
      let verifySave = this.$refs.WinResultRef.verifySave()
      if (!verifySave) {
        return this.$message.error('请补充完整未中标数据')
      }
      let file = this.$refs.WinResultRef.save()

      if (file == 'warning') return
      if (file.isWin === '') {
        req.bidManagementInfoReq.bidStatus = 'bidding'
      } else {
        req.bidManagementInfoReq.bidStatus = file.isWin
      }
     
      delete req.bidManagementInfoReq.bidUnitPriceReq
      req.bidUnitPriceReq = file.bidUnitPriceReq
      req.bidUnitPriceDeleteList = file.bidUnitPriceDeleteList
      req.bidManagementInfoReq.bidWinningAmount = file.bidWinningAmount
      req.bidManagementInfoReq.bidWinningAmountType = file.bidWinningAmountType
      // 中标文件
      req.bidManagementFileAddList = file.fileList
       // 招标附件
      if(req.bidManagementInfoReq.bidManagementFileAddList && req.bidManagementInfoReq.bidManagementFileAddList.length > 0) {
        req.bidManagementInfoReq.bidManagementFileAddList.map(item => {
          console.log('item',item)
          item.bidType = 'bidFile'
          item.type = 'ZBWJFJ'
          item.fkId = item.staffId
          item.fileName = item.attachmentName,
          item.filePath = item.attachmentPath,
          req.bidManagementFileAddList.push(item)
        })
        // req.bidManagementFileAddList.push(...req.bidManagementInfoReq.bidManagementFileAddList)
      }
      req.bidManagementFileDeleteList = file.deleteFileList
      if(req.bidManagementInfoReq.bidManagementFileDeleteList && req.bidManagementInfoReq.bidManagementFileDeleteList.length > 0) {
        req.bidManagementInfoReq.bidManagementFileDeleteList.map(item => {
          req.bidManagementFileDeleteList.push(item)
        })
      }
      req.bidAnalysisReq = file.bidAnalysisResp
      req.bidAnalysisReq.bidManagementId = this.data.id ? this.data.id : null
      req.bidMembers = memberArr
      req.deleteBidMemberList = deleteBidMemberList

      this.loading = true
      const res = await this.$api.bid.addBid(req)
      this.SET_ZP_Members([])
      this.$emit('update:isDetailShow', false)
      this.loading = false
      this.$message.success('操作成功！')
      this.re()
    },

    async getData() {
      this.loading = true
      const { data } = await this.$api.bid.selectDetailBid(this.data.id)
      if (data) {
        this.fakeData = data.deepClone()
        this.fakeData.bidManagementCost = this.fakeData.bidManagementCost
          ? this.fakeData.bidManagementCost
          : {}
        this.detailData = data.deepClone()
        this.winResultData = {
          fkId: this.detailData.id,
          isWin: this.detailData.bidStatus,
          fileList: this.detailData.bidManagementWinFiles,
          bidAnalysisResp: this.detailData.bidAnalysisResp,
          bidUnitPriceReq: this.detailData.bidUnitPriceReq,
          bidWinningAmountType: this.detailData.bidWinningAmountType,
          bidWinningAmount: this.detailData.bidWinningAmount,
        }
      }
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
/deep/.el-loading-spinner {
  margin-top: 40%;
}
</style>
